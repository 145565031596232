import * as React from "react";
import Meta from "../../../components/meta";
import Styled from "styled-components/macro";
import Media from "@uizard-io/styled-media-query";
import { graphql } from "gatsby";
import constants from "constants/index";
import Content from "../../../components/layout/Content/Content";
import Layout from "../../../components/layout/Layout";
import HomeFeatured from "../../../components/pages/home/HomeFeatured";
import HomeExamples from "../../../components/pages/home/HomeExamples";
import HomeAutodesigner from "../../../components/pages/home/HomeAutodesigner";
import HomeAutodesignerLeft from "../../../components/pages/home/HomeAutodesignerLeft";
import HeroBigTextBannerSquareImage from "../../../components/pages/solutions/HeroBigTextBannerSquareImage";
import HomeWireframe from "../../../components/pages/home/HomeWireframe";
import AiHeatmap from "../../../components/pages/ai/AiHeatmap";
import Shoutout from "../../../components/pages/signup/Shoutout";


const Container = Styled.div`
  margin: 10rem 0 10rem 0;

  ${Media.greaterThan("medium")`
    margin: 12rem 0 10rem 0;
  `}
`;

const ContentExtended = Styled(Content)`
  margin-top: 9rem;
`;

const Analyst = ({ data }) => (
  <Layout>
    <Meta
      title="Uizard For Marketers & Marketing Specialists | Uizard"
      description="Create mockups for websites, apps, and user interfaces effortlessly. Work collaboratively with stakeholders and supercharge your productivity like never before."
      url="/solutions/marketers/"
    />

    <Container>

      <HeroBigTextBannerSquareImage
        tagline="Uizard for marketers"
        headline="Create mockups for clients in seconds"
        description="Craft the perfect pitch, nail your next QBR, or get key strategy proposals signed off with ease. Showcasing your expertise and communicating your ideas has never been easier."
        imageA={data.prototypingImageA.childImageSharp}
        imageB={data.prototypingImageB.childImageSharp}
        imageAlt="users collaboratively designing a mobile app wireframe in uizard"
        link={`https://${constants.APP_DOMAIN}/auth/sign-up`}
        cta="Sign up for free"
        tagAsTitle={true}
      />

      <HomeAutodesignerLeft
        headline="Turn screenshots into editable designs"
        description="Upload screenshots of your client's app or website and transform them into editable mockups with AI."
        description2="Designing new landing pages and providing optimization recommendations just got a whole lot easier."
        imageA={data.hificonversionImageA.childImageSharp}
        imageB={data.hificonversionImageB.childImageSharp}
        imageAlt="turn screenshot into editable UI mockups"
        videoName="uizard-scan-screenshot"
        link="/screenshot/"
        videoH={450}
      />

      <AiHeatmap
        headline="Support your deliverables with user heatmaps"
        description="Use Uizard's Focus Predictor to instantly analyze your app or web mockups."
        description2="Provide your clients with heatmaps that showcase how their users will interact with your designs. Ensure your proposals land first time and save countless hours on iteration."
        imageAlt="uizard AI-generated heatmaps"
        imageA={data.heatmapImageA.childImageSharp}
        imageB={data.heatmapImageB.childImageSharp}
        videoName="uizard-heatmap-generation"
        link=""
      />

      <HomeWireframe
        headline="From low to high fidelity in an instant"
        description="Use Uizard's Wireframe Mode to instantly switch between low and high fidelity."
        description2="Whether you're sharing your mockups with a client team or presenting your designs as part of a pitch, tailoring your mockups to a specific audience has never been easier."
        imageA={data.wireframesToMockupsBig.childImageSharp}
        imageB={data.wireframesToMockupsSmall.childImageSharp}
        videoName="uizard-wireframe-high-fi-mode"
        imageAlt="Visual showing Uizard's features for rendering a design as a low-fi wireframe or as an high-fi mockup"
        videoW={600}
        videoH={600}
      />

      <HomeAutodesigner
        headline="Design made easy — powered by AI"
        description="Uizard's easy-to-use, drag-and-drop editor empowers you to design and collaborate faster than ever before. Kickstart your project from scratch or supercharge your productivity with AI."
        imageA={data.autodesignerImageA.childImageSharp}
        imageB={data.autodesignerImageB.childImageSharp}
        imageAlt="turn text prompts into UI designs with AI"
        videoName="uizard-autodesigner-light"
        link="/autodesigner/"
      />

      <HomeFeatured />
      <HomeExamples />

      <ContentExtended>
        <Shoutout
          headline="Design. Simplified"
          description="App, web, and UI design made easy with the power of AI."
          cta="Sign up for free"
          imageA={data.shoutoutImageA.childImageSharp}
        />
      </ContentExtended>
    </Container>
  </Layout>
);

export default Analyst;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    prototypingImageA: file(
      relativePath: { eq: "wireframing/Wireframe_Cover_2x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1096, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    prototypingImageB: file(
      relativePath: { eq: "wireframing/Wireframe_Cover_1x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 548, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    hificonversionImageA: file(
      relativePath: { eq: "ai/HifiConversion-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    hificonversionImageB: file(
      relativePath: { eq: "ai/HifiConversion-b.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 501, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    heatmapImageA: file(relativePath: { eq: "ai/heatmap-a.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    heatmapImageB: file(relativePath: { eq: "ai/heatmap-b.png" }) {
      childImageSharp {
        fluid(maxWidth: 501, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    wireframesToMockupsBig: file(
      relativePath: { eq: "wireframing/WireframesToMockups_2x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    wireframesToMockupsSmall: file(
      relativePath: { eq: "wireframing/WireframesToMockups_1x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    autodesignerImageA: file(
      relativePath: { eq: "ai/uizard-autodesigner-light-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    autodesignerImageB: file(
      relativePath: { eq: "ai/uizard-autodesigner-light-b.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    shoutoutImageA: file(
      relativePath: { eq: "shoutout/shoutout-image-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1232, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
